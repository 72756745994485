import api from "@/api";
import Search from "@/components/Search";
import { SearchListRow } from "@/models/type";
import { Vue } from "vue-class-component"
import OrderChart from './charts/orderCharts'
import { h } from 'vue'
import './index.scss'
import { getTimeCell } from "@/utils";

const start_time = new Date(getTimeCell(30)[0] as string)
const end_time = new Date(getTimeCell(30)[1] as string)

export default class SignupSubject extends Vue {

  private searchList: Array<SearchListRow> = [
    { label: '系列课', type: 'autocomplete', submitKey: 'subjectId', querySearchAsync: this.querySearchAsync }
  ]

  public charData = []

  public showObj: any = {}
  public searchObj: any = {
    start_time,
    end_time
  }

  querySearchAsync(queryString, cb): void {
    api.systemGetSubjectListBySubjectName({title: queryString}).then(res => {
      const list = res.data.map(item => ({value: item.title, id: item.id}))
      cb(list)
    })
  }

  charDom(): JSX.Element {
    return <div class="order-business-char">
      <div class="order-business-char-header">
        <div class="order-business-char-title">签到</div>
      </div>
      {h(OrderChart, { data: this.charData })}
    </div>
  }

  showDom(): JSX.Element {
    return <div class="order-business-char-show">
      <div>
        <span>课程类型：</span>
        <span>{this.showObj?.type}</span>
      </div>
      <div>
        <span>指导老师：</span>
        <span>{this.showObj?.tutor}</span>
      </div>
      <div>
        <span>报名人数：</span>
        <span>{this.showObj?.sum}</span>
      </div>
    </div>
  }
  onSearch(row: any): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  getList(): void {
    api.systemGetSubjectStatistics(this.searchObj).then(res => {
      this.charData = res.data.data
      this.showObj = res.data.statistics
    })
  }

  render(): JSX.Element {
    return <div class="order-business">
      <Search list={this.searchList} onSearch={this.onSearch} />
      {this.showDom()}
      <el-divider />
      {this.charDom()}
    </div>
  }
}
