import { Prop, Vue, Watch } from "vue-property-decorator";
import * as echarts from 'echarts'

export default class OrderCharts extends Vue {
  public chart: any = {}

  @Prop({type: Object, default: () => ({})}) readonly data?: any

  public options: any = {}

  @Watch('data', { deep: true, immediate: true})
  initChart(val: Array<any>): void {
    const time = val.map(item => item.title)
    const data = val.map(item => item.count)
    this.options = {
      /* dataZoom: [
        {
          type: 'slider'
        }
      ], */
      grid: {
        top: 65,
        left: 50,
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        className: 'default-tooltip',
        axisPointer: {
          animation: false
        }
      },
      toolbox: {
        show: true,
        feature: {
          // /* dataZoom: {
          //   yAxisIndex: "none"
          // }, */
          // dataView: {
          //   readOnly: true
          // },
          // magicType: {
          //   type: ["line", "bar"]
          // },
          // restore: {},
          saveAsImage: {}
        },
        orient: "horizontal"
      },
      xAxis: {
        type: 'category',
        name: '活动名称',
        data: time,
        axisLabel: { interval: 0, rotate: 30 }
      },
      yAxis: [
        {
          type: 'value',
          name: '签到数量',
          nameTextStyle: {
            color: '#69B3E7'
          },
          axisLine: {
            show: false
          }
        }
      ],
      series: [{
        type: 'bar',
        smooth: false,
        symbol: 'circle',
        symbolSize: 8,
        name: '签到数量',
        label: {
          show: true,
          position: 'top',
          color: 'rgba(105,179,231,1)'
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
            offset: 0,
            color: 'rgba(90,197,255,1)' // 0% 处的颜色
          }, {
            offset: 1,
            color: 'rgba(160,54,255,1)' // 100% 处的颜色
          }], false)
        },
        lineStyle: {
          width: 1
        },
        data: data
      }]
    }
    this.$nextTick(() => {
      const __order_charts = this.$refs['order-charts'] as HTMLElement
      this.chart = echarts.init(__order_charts)
      this.chart.setOption(this.options, true)
    })
  }

  beforeUnmount(): void {
    this.chart.dispose()
    this.chart = {}
  }
  render(): JSX.Element {
    return <div
      ref="order-charts"
      class="chart"
      style={{
        width: '100%',
        height: '500px'
      }}
    >
    </div>
  }
}
