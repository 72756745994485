import api from "@/api";
import Search from "@/components/Search";
import { SearchListRow } from "@/models/type";
import { Vue } from "vue-class-component"
import OrderChart from './charts/orderCharts'
import { h } from 'vue'
import './index.scss'
import { getTimeCell } from "@/utils";

const start_time = new Date(getTimeCell(30)[0] as string)
const end_time = new Date(getTimeCell(30)[1] as string)

export default class SignupActivity extends Vue {

  private searchList: Array<SearchListRow> = [
    { label: '时间', type: 'time', submitKey: ['start_time', 'end_time'], value: [start_time, end_time] }
  ]

  public charData = []
  public searchObj: any = {
    start_time,
    end_time
  }

  charDom(): JSX.Element {
    return <div class="order-business-char">
      <div class="order-business-char-header">
        <div class="order-business-char-title">签到</div>
      </div>
      {h(OrderChart, { data: this.charData })}
    </div>
  }
  onSearch(row: any): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  getList(): void {
    api.systemGetActivityStatistics(this.searchObj).then(res => {
      this.charData = res.data
    })
  }

  render(): JSX.Element {
    return <div class="order-business">
      <Search list={this.searchList} onSearch={this.onSearch} />
      <el-divider />
      {this.charDom()}
    </div>
  }
  mounted(): void {
    this.getList()
  }
}
