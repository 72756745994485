import Tabs from "@/components/Tabs";
import { OptionsRow, TabListRow } from "@/models/type";
import { Vue } from "vue-class-component";
import SignupActivity from "./components/SignupActivity";
import SignupCell from "./components/SignupCell";
import SignupSubject from "./components/SignupSubject";
import './index.scss'

export default class StatisticsSignup extends Vue {

  public activeName = 'cell'

  public tabList: Array<TabListRow> = [
    {title: '报名记录', activeName: 'cell'},
    {title: '课程签到图', activeName: 'subject'},
    {title: '活动签到图', activeName: 'activity'},
  ]

  activechange(activeName: string): void {
    this.activeName = activeName
  }

  render(): JSX.Element {
    const slot = {
      cell: () => <SignupCell/>,
      subject: () => <SignupSubject/>,
      activity: () => <SignupActivity/>
    }
    return <div class="statistics">
      <el-card>
        <Tabs
          tabList={this.tabList}
          activeName={this.activeName}
          onActivechange={this.activechange}
          v-slots={slot}
        />
      </el-card>
    </div>
  }
}
