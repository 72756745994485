import api from "@/api"
import Pagination from "@/components/Pagination"
import Search from "@/components/Search"
import { OptionsRow, PaginationRow, SearchListRow, TableRow } from "@/models/type"
import { parseTime } from "@/utils"
import { Vue } from "vue-class-component"

export default class SignupCell extends Vue {
  public total = 0
  public tableData: Array<any> = []

  public typeList: OptionsRow [] = [
    { name: '课程', value: 'subject' },
    { name: '活动', value: 'activity' }
  ]

  public searchObj: any = {
    type: 'subject'
  }

  public payTypeList: OptionsRow[] = [
    { name: '微信支付', value: 2 },
    { name: '支付宝支付', value: 1 },
    { name: 'PayMe支付', value: 5 },
    { name: 'VISA支付', value: 3 },
    { name: 'Master支付', value: 4 }
  ]

  private searchList: Array<SearchListRow> = [
    { label: '类型', value: 'subject', type: 'select', placeholder: '请选择订单状态', options: this.typeList, submitKey: 'type' },
    { label: '名称', type: 'input',  placeholder: '请输入名称', submitKey: 'title' }
  ]
  private tableList: Array<TableRow> = [
    { label: '类型', slot: {
      default: (scope: any) => <span>{this.typeList.find(item => item.value === this.searchObj.type)?.name}</span>
    } },
    {label: '名称', prop: 'title'},
    {label: '真实姓名', prop: 'memberName'},
    {label: '用户身份', prop: 'levelName'},
    {label: '所属机构', prop: 'teamTitle'},
    {label: '支付金额', prop: 'payPrice'},
    { label: '支付方式', prop: 'payType', slot: {
      default: (scope: any): JSX.Element => <span>{ this.payTypeList.find(item => item.value === scope.row.payType)?.name }</span>
    } },
    {label: '支付时间', slot: {
      default: (scope: any): JSX.Element => <span>{ parseTime(new Date(scope.row.payTime).getTime()) }</span>
    }},
    {label: '返款金额', prop: 'returnPrice'},
  ]


  getList(): void {
    this.tableData = []
    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } =__pagination.paginationObj as PaginationRow
    api.systemGetSignUpList({ pageNum, pageSize, ...this.searchObj }).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  onSearch(row: any): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  tableDom(): JSX.Element {
    return <el-table
      data={this.tableData}
    >
      {this.tableList.map(item => (
        <el-table-column
          label={ item.label }
          prop={ item.prop }
          v-slots={item.slot}
          align="center"
        />
      ))}
    </el-table>
  }


  render(): JSX.Element {
    return <div>
      <Search list={this.searchList} onSearch={this.onSearch} />
      {this.tableDom()}
      <Pagination ref="pagination" total={this.total} onSizeChange={this.getList} onCurrentChange={this.getList} />
    </div>
  }

  mounted(): void {
    this.getList()
  }
}
